import { useEffect, useState } from 'react';
import { BaseLinearProgress } from '@sonar-web/common';
import AddressesList from './AddressesList';
import FetchServersAddressesData from './FetchServersAddressesData';
import ServersAddressesHeader from './ServersAddressesHeader';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import Messages from '../Constants/Messages';

const ServersAddresses = () => {
	const [resetKey, setResetKey] = useState(0);

	useEffect(() => {
		const onPortAllocationRequested = () => setResetKey((prev) => prev + 1);

		EventBus.on(Messages.PortAllocationRequested, onPortAllocationRequested);
		return () => {
			EventBus.off(Messages.PortAllocationRequested, onPortAllocationRequested);
		};
	}, []);

	return (
		<>
			<ServersAddressesHeader />
			<FetchServersAddressesData key={resetKey}>
				{({ pending, data }) => (
					<>
						<BaseLinearProgress pending={pending} />
						{data && <AddressesList data={data} />}
					</>
				)}
			</FetchServersAddressesData>
		</>
	);
};

export default ServersAddresses;
