import { useSelector } from 'react-redux';
import moment from 'moment';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from '@mui/icons-material/Close';
import { selectLocale } from '../../features/Locales/localesSlice';
import { useLocale } from '../../hooks';

const BaseDatePicker = ({
	label,
	value,
	onChange,
	onClear,
	error,
	helperText,
	FormHelperTextProps,
	fullWidth = false,
	mask = '__.__.__',
	format = moment.localeData().longDateFormat('L'),
	textFieldProps = {},
	handleOnChange = false,
	...rest
}) => {
	const locale = useSelector(selectLocale);
	const { translate } = useLocale();

	const handleChange = (v) => {
		const { disableFuture, minDate } = { ...rest };
		let invalidFuture = false;
		let invalidPast = false;

		if (!v?.isValid()) return;
		if (disableFuture) invalidFuture = v.isAfter(moment(), 'day');
		if (minDate) invalidPast = v.isBefore(moment(minDate), 'day');

		if (invalidFuture) onChange(moment());
		else if (invalidPast) onChange(moment(minDate));
		else onChange(v);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
			<DatePicker
				disableMaskedInput
				mask={mask}
				variant='inline'
				format={format}
				label={label ? translate(label) : null}
				value={value}
				onChange={handleOnChange ? onChange : handleChange}
				clearable={false}
				renderInput={(props) => (
					<TextField
						{...props}
						value={value}
						fullWidth={fullWidth}
						error={error}
						helperText={translate(helperText)}
						size='small'
						{...textFieldProps}
						sx={{
							'& .MuiInputAdornment-root': {
								marginLeft: 0
							}
						}}
						slotProps={{
							formHelperText: FormHelperTextProps,
							input: {
								endAdornment: (
									<>
										{onClear && value && (
											<InputAdornment position='end'>
												<IconButton
													aria-label='filter_date_clear'
													onClick={(e) => {
														e.stopPropagation();
														onClear();
													}}>
													<CloseIcon />
												</IconButton>
											</InputAdornment>
										)}
										{props.InputProps.endAdornment}
									</>
								)
							}
						}}
					/>
				)}
				{...rest}
			/>
		</LocalizationProvider>
	);
};

export default BaseDatePicker;
