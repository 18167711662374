import { Fragment, useState, useEffect } from 'react';
import { useListFiltersContext } from './listFiltersContext';
import FilterOperator from '../../enums/FilterOperator';

const FiltersControlAutocompleteMultiNew = ({
	member,
	label,
	operator = FilterOperator.CollectionContain.value,
	valueName = 'id',
	disabled,
	children,
	valueAsString = false
}) => {
	const { listFilters } = useListFiltersContext();
	const listFiltersValue = listFilters.getByMemberAndOperator(member.name, operator);
	const defaultValue = listFiltersValue?.value ? listFiltersValue.value.split(';') : [];
	const [key, setKey] = useState(0);

	const onSelect = (selected) => {
		if (selected.length === 0) {
			listFilters.clearByMemberAndOperator(member.name, operator);
			return;
		}

		const values = selected.map((s) => s[valueName]);
		const newValue = valueAsString ? values.join(';') : values;

		listFilters.set({
			member,
			value: newValue,
			operator
		});
	};

	useEffect(() => {
		if (listFiltersValue) return;
		setKey((prev) => prev + 1);
	}, [listFiltersValue]);

	return <Fragment key={key}>{children({ label, onSelect, defaultValue, disabled })}</Fragment>;
};

export default FiltersControlAutocompleteMultiNew;
