import { Box, Typography } from '@mui/material';
import { TableListColumn, TableListItem } from '@sonar-web/common';
import { useLocale } from '@sonar-web/common/src/hooks';
import RequestPortAllocation from './RequestPortAllocation';

const AddressesListItem = ({ title, description, domain, ip, port, type }) => {
	const { translate } = useLocale();

	const domainAddress = domain ? `${domain}:${port}` : '';
	const ipAddress = ip ? `${ip}:${port}` : '';

	return (
		<TableListItem rolistItemProps={{ 'data-at': 'addressesListItem' }}>
			<Box display='flex' alignItems='center'>
				<TableListColumn flex data-at='addressesListItemName' disableLeftPadding>
					<Typography variant='body2'>{translate(title)}</Typography>
					<Typography variant='caption' color='textSecondary'>
						{translate(description)}
					</Typography>
				</TableListColumn>
				{port && (
					<TableListColumn
						width='auto'
						disableLeftPadding
						sx={{ flex: 'none' }}
						data-at='addressesListItemNameDomain'>
						<Typography variant='body2' width='fit-content'>
							{domainAddress}
						</Typography>
					</TableListColumn>
				)}
				{port && (
					<TableListColumn
						width={150}
						data-at='addressesListItemNameIp'
						sx={{ textAlign: 'right' }}
						disableLeftPadding
						disableRightPadding>
						<Typography variant='body2'>{ipAddress}</Typography>
					</TableListColumn>
				)}
				{!port && (
					<TableListColumn width='auto' disableLeftPadding disableRightPadding>
						<RequestPortAllocation type={type} />
					</TableListColumn>
				)}
			</Box>
		</TableListItem>
	);
};

export default AddressesListItem;
