import { apiBase, timeout } from '@sonar-web/common';
import { REST_API_PATHS } from '@sonar-web/common/src/constants/system';

const path = REST_API_PATHS.TENANT;

async function fetchTenantsTimeLicenses() {
	const { data } = await apiBase(`tenants/timeLicences`, 'get', {
		path
	});

	return data;
}

async function fetchTenantLicense(id) {
	const { data } = await apiBase(`tenants/${id}/licence`, 'get', {
		path
	});

	return data;
}

async function fetchServerAddresses() {
	const { data } = await apiBase(`tenant/ports`, 'get', {
		path
	});

	return data;
}

async function forceRefreshLicense() {
	const { data } = await apiBase(`license/force`, 'post', {
		path,
		data: {}
	});

	return data;
}

async function fetchTenantsMode() {
	const { data } = await apiBase(`tenants/mode`, 'get', {
		path
	});

	return data?.itsMultiTenant;
}

async function fetchTenantSettings() {
	const { data } = await apiBase(`tenant/settings`, 'get', {
		path
	});

	return data;
}

async function addTenantSettings(billingPeriodStartDate) {
	const { data } = await apiBase(`tenant/settings`, 'post', {
		path,
		data: { settings: { billingPeriodStartDate } }
	});

	return data;
}

async function requestPortAllocation({ type, tenantId }) {
	const { data } = await apiBase(`tenants/${tenantId}/activate-port/${type}`, 'post', {
		path,
		data: {}
	});

	return data;
}

const TenantsService = {
	fetchTenantsTimeLicenses,
	fetchTenantLicense,
	fetchServerAddresses,
	forceRefreshLicense,
	fetchTenantsMode,
	fetchTenantSettings,
	addTenantSettings,
	requestPortAllocation
};

export default TenantsService;
