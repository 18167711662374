import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

/**
 * useListFilters - A custom hook to manage list filters via URL search parameters.
 *
 * @param {Array} filtersArray - An array of filters to be managed.
 * @returns {Object} An object containing:
 * - {URLSearchParams} filtersParams - The current filters as URLSearchParams.
 * - {boolean} hasCurrentFilters - Indicates if there are filters applied.
 * - {function} set - Sets a new filter.
 * - {function} get - Gets the current filters.
 * - {function} getByMemberAndOperator - Gets a filter by member and operator.
 * - {function} clear - Clears all filters.
 * - {function} clearByMemberAndOperator - Clears a filter by member and operator.
 */
export const useListFilters = (filtersArray = []) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const filtersParams = useMemo(() => {
		const newSearchParams = new URLSearchParams(searchParams);

		searchParams.forEach((_, key) => {
			if (!filtersArray.find((filter) => key === getFilterKey(filter))) newSearchParams.delete(key);
		});

		return newSearchParams;
	}, [searchParams]);

	const set = (newValue, useSingleOperator = true) => {
		const newSearchParams = new URLSearchParams(location.search);

		if (useSingleOperator) {
			newSearchParams.forEach((_, key) => {
				const [member] = key.split('_');
				if (member === newValue.member.name) newSearchParams.delete(key);
			});
		}

		const newFilters = createFilterObject(newValue);

		Object.entries(newFilters).forEach(([key, value]) => {
			newSearchParams.set(key, value);
		});

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const get = () => {
		const filterArray = [];
		if (searchParams.size === 0) return filterArray;

		searchParams.forEach((value, key) => {
			const [member, operator] = key.split('_');
			if (filtersArray.some((filter) => key === getFilterKey(filter)))
				filterArray.push({ member, value, operator });
		});

		return filterArray;
	};

	const getByMemberAndOperator = (memberToFind, operatorToFind) => {
		const key = `${memberToFind}_${operatorToFind}`;
		const currentFilter = searchParams.get(key);

		if (currentFilter == null) return null;
		return { member: memberToFind, value: currentFilter, operator: operatorToFind };
	};

	const clear = () => {
		if (filtersArray.length === 0) return;

		const newSearchParams = new URLSearchParams(searchParams);
		filtersArray.forEach((fa) => newSearchParams.delete(getFilterKey(fa)));

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	const clearByMemberAndOperator = (memberToFind, operatorToFind) => {
		const key = `${memberToFind}_${operatorToFind}`;

		const newSearchParams = new URLSearchParams(location.search);
		newSearchParams.delete(key);

		navigate({
			pathname: window.location.pathname,
			search: newSearchParams.toString()
		});
	};

	return {
		filtersParams,
		hasCurrentFilters: filtersParams.size > 0,
		set,
		get,
		getByMemberAndOperator,
		clear,
		clearByMemberAndOperator
	};
};

const createFilterObject = (obj) => {
	const { member, value, operator } = obj;
	const key = `${member.name}_${operator}`;

	return { [key]: value };
};

const getFilterKey = (filterObject) => {
	return `${filterObject.member.name}_${filterObject.operator}`;
};
