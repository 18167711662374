import { forwardRef } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { useLocale } from '../../hooks';
import { BaseDatePicker, BaseTooltip } from '..';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TimeRange from '../../enums/TimeRange';
import FilterOperator from '../../enums/FilterOperator';
import { DateHelpers } from '../../helpers';
import { useListFiltersContext } from './listFiltersContext';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	marginTop: '-1rem',
	alignItems: 'space-between',
	gap: '.5rem',
	'& .MuiToggleButtonGroup-grouped': {
		flex: 1,
		'&:not(:first-of-type)': {
			borderRadius: theme.shape.borderRadius,
			borderLeftColor: 'rgba(41, 61, 77, 0.12)'
		},
		'&:first-of-type': {
			borderRadius: theme.shape.borderRadius
		}
	}
}));

const TooltipToggleButton = forwardRef(({ TooltipProps, value, children, ...props }, ref) => {
	return (
		<BaseTooltip {...TooltipProps}>
			<ToggleButton ref={ref} value={value} {...props}>
				{children}
			</ToggleButton>
		</BaseTooltip>
	);
});

const FiltersControlDateNew = ({
	member,
	label,
	type,
	withPredefined = false,
	disableFuture = true,
	useSingleLocalDate = false,
	useSingleOperator = true
}) => {
	const { translate } = useLocale();
	const { listFilters } = useListFiltersContext();
	const listFiltersValue = listFilters.getByMemberAndOperator(member.name, type);
	const value = listFiltersValue ? moment(listFiltersValue.value) : null;

	const onChange = (date) => {
		if (!date) {
			listFilters.clearByMemberAndOperator(member.name, type);
			return;
		}
		if (!date.isValid() || date.isSame(value)) return;

		const filterValue = generateFilterValue(date, member, type, useSingleLocalDate);
		listFilters.set(filterValue, useSingleOperator);
	};

	const onClear = () => {
		listFilters.clearByMemberAndOperator(member.name, type);
	};

	const onPredefinedChange = (newValue) => {
		if (newValue === null) return onClear();
		onChange(moment().subtract(newValue, 'months'));
	};

	return (
		<>
			<BaseDatePicker
				label={label}
				disableFuture={disableFuture}
				value={value}
				handleOnChange={true}
				onChange={onChange}
				onClear={onClear}
				fullWidth={true}
				textFieldProps={{
					variant: 'outlined'
				}}
			/>
			{withPredefined && (
				<StyledToggleButtonGroup
					size='small'
					value={value}
					exclusive
					onChange={onPredefinedChange}
					aria-label='predefined date options'>
					<TooltipToggleButton
						value={1}
						TooltipProps={{ placement: 'bottom', text: TimeRange.Months_1.nameLong }}
						aria-label={translate(TimeRange.Months_1.nameLong)}>
						{translate(TimeRange.Months_1.nameShort)}
					</TooltipToggleButton>
					<TooltipToggleButton
						value={3}
						TooltipProps={{ placement: 'bottom', text: TimeRange.Months_3.nameLong }}
						aria-label={translate(TimeRange.Months_3.nameLong)}>
						{translate(TimeRange.Months_3.nameShort)}
					</TooltipToggleButton>
					<TooltipToggleButton
						value={6}
						TooltipProps={{ placement: 'bottom', text: TimeRange.Months_6.nameLong }}
						aria-label={translate(TimeRange.Months_6.nameLong)}>
						{translate(TimeRange.Months_6.nameShort)}
					</TooltipToggleButton>
				</StyledToggleButtonGroup>
			)}
		</>
	);
};

export default FiltersControlDateNew;

const generateFilterValue = (date, member, type, useSingleLocalDate) => {
	const filterValue = {
		member,
		operator: type
	};

	if (type === FilterOperator.Ge.value) filterValue.value = date.startOf('day').toISOString();
	else if (type === FilterOperator.Lt.value) filterValue.value = date.endOf('day').toISOString();
	else if (type === FilterOperator.Equal.value)
		filterValue.value = DateHelpers.dateAsIsoStringLocal(date.startOf('day'), false, true);
	else if (useSingleLocalDate) filterValue.value = DateHelpers.dateAsIsoStringLocal(date, false, true);
	else filterValue.value = date.startOf('day').toISOString();

	return filterValue;
};
