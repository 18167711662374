import { createContext, useContext, useRef, useState } from 'react';
import { useListFilters } from './useListFilters';

const ListFiltersContext = createContext();

export const ListFiltersContextProvider = ({ filtersArray, children }) => {
	const filtersKeys = useRef(createFiltersKeys(filtersArray));
	const listFilters = useListFilters(filtersArray);
	const [anchor, setAnchor] = useState(null);

	const open = Boolean(anchor);

	const hasCurrentFilters = () => {};

	const openFilters = (e) => setAnchor(e.currentTarget);

	const closeFilters = () => setAnchor(null);

	return (
		<ListFiltersContext.Provider
			value={{
				anchor,
				setAnchor,
				openFilters,
				closeFilters,
				open,
				listFilters,
				filtersKeys: filtersKeys.current,
				hasCurrentFilters
			}}>
			{children}
		</ListFiltersContext.Provider>
	);
};

export const useListFiltersContext = () => useContext(ListFiltersContext);

export default ListFiltersContext;

const createFiltersKeys = (filtersArray) => {
	if (!filtersArray) return [];
	return filtersArray.map((filter) => {
		const { member, operator } = filter;
		return `${member.name}_${operator}`;
	});
};
