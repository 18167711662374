import FiltersControlTextSingle from './FiltersControlTextSingle';
import FiltersControlAutocompleteSingle from './FiltersControlAutocompleteSingle';
import FiltersControlDate from './FiltersControlDate';
import FiltersControlComboSingle from './FiltersControlComboSingle';
import FiltersControlNumberRange from './FiltersControlNumberRange';
import FiltersControlAutocompleteMulti from './FiltersControlAutocompleteMulti';
import FiltersControlAutocomplete from './FiltersControlAutocomplete';
import FiltersControlComboMulti from './FiltersControlComboMulti';
import FiltersControlDateNew from './FiltersControlDateNew';
import FiltersControlTextSingleNew from './FiltersControlTextSingleNew';
import FiltersControlAutocompleteMultiNew from './FiltersControlAutocompleteMultiNew';
import { FiltersControlComboSingleNew } from './FiltersControlComboSingleNew';

const FiltersControls = {
	textSingle: FiltersControlTextSingle,
	autocompleteSingle: FiltersControlAutocompleteSingle,
	date: FiltersControlDate,
	comboSingle: FiltersControlComboSingle,
	comboMulti: FiltersControlComboMulti,
	numberRange: FiltersControlNumberRange,
	autocompleteMulti: FiltersControlAutocompleteMulti,
	autocomplete: FiltersControlAutocomplete,
	NewDate: FiltersControlDateNew,
	NewTextSingle: FiltersControlTextSingleNew,
	NewAutocompleteMulti: FiltersControlAutocompleteMultiNew,
	NewComboSingle: FiltersControlComboSingleNew
};

export default FiltersControls;
