import { FormControl, Select, InputLabel, IconButton, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale } from '../../hooks';
import BaseSelectMenuItem from '../BaseSelectMenuItem/BaseSelectMenuItem';
import { useListFiltersContext } from './listFiltersContext';
import FilterOperator from '../../enums/FilterOperator';

export const FiltersControlComboSingleNew = ({
	member,
	label,
	operator = FilterOperator.Equal.value,
	options,
	disabled = false
}) => {
	const { translate } = useLocale();
	const { listFilters } = useListFiltersContext();
	const listFiltersValue = listFilters.getByMemberAndOperator(member.name, operator);
	const value = listFiltersValue ? listFiltersValue.value : '';

	const onChange = (event) => {
		const newValue =
			operator === FilterOperator.CollectionContain.value ? [event.target.value] : event.target.value;

		listFilters.set({
			member,
			operator,
			value: newValue
		});
	};

	const onClear = () => {
		listFilters.clearByMemberAndOperator(member.name, operator);
	};

	return (
		<FormControl variant='outlined' size='small' fullWidth disabled={disabled}>
			<InputLabel>{translate(label)}</InputLabel>
			<Select
				value={value}
				label={translate(label)}
				onChange={onChange}
				size='small'
				variant='outlined'
				endAdornment={
					value && (
						<InputAdornment
							position='end'
							sx={{
								marginLeft: 0,
								marginRight: '.9rem'
							}}>
							<IconButton size='small' aria-label='clear filter' onClick={onClear}>
								<CloseIcon fontSize='small' />
							</IconButton>
						</InputAdornment>
					)
				}>
				{options.map((opt) => {
					return (
						<BaseSelectMenuItem key={opt.value} value={opt.value}>
							{translate(opt.text)}
						</BaseSelectMenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
