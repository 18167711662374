import { useState, useEffect } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale, useDebounce } from '../../hooks';
import BaseTooltip from '../BaseTooltip/BaseTooltip';
import { PartialMatchIcon, ExactMatchIcon } from '../../icons';
import { useListFiltersContext } from './listFiltersContext';
import FilterOperator from '../../enums/FilterOperator';

const FiltersControlTextSingleNew = ({ member, label, useSingleOperator = true }) => {
	const { translate } = useLocale();
	const { listFilters } = useListFiltersContext();
	const [exactMatch, setExactMatch] = useState(false);
	const operator = exactMatch ? FilterOperator.Equal.value : FilterOperator.Like.value;
	const listFiltersValue = listFilters.getByMemberAndOperator(member.name, operator);
	const [value, setValue] = useState(listFiltersValue?.value || '');
	const debounceSearchTerm = useDebounce(value);

	const handleChange = (event) => {
		const newValue = event.target.value;
		if (!newValue) listFilters.clearByMemberAndOperator(member.name, operator);
		else setValue(newValue);
	};

	const handleToggleExactMatch = () => setExactMatch(!exactMatch);

	const onClear = () => {
		setValue('');
		listFilters.clearByMemberAndOperator(member.name, operator);
	};

	useEffect(() => {
		if (!debounceSearchTerm) return;

		listFilters.set(
			{
				member,
				operator,
				value: debounceSearchTerm
			},
			useSingleOperator
		);
	}, [debounceSearchTerm, operator]);

	useEffect(() => {
		setValue(listFiltersValue?.value || '');
	}, [listFiltersValue?.value]);

	return (
		<TextField
			name={member.name}
			label={translate(label)}
			value={value}
			type='text'
			size='small'
			variant='outlined'
			onChange={handleChange}
			fullWidth
			slotProps={{
				input: {
					margin: 'none',
					sx: { paddingRight: '.5rem' },
					endAdornment: (
						<InputAdornment position='end'>
							{value && (
								<IconButton
									name={`${member.name}Clear`}
									size='small'
									aria-label='filter_text_clear'
									onClick={onClear}>
									<CloseIcon fontSize='small' />
								</IconButton>
							)}
							<BaseTooltip text={exactMatch ? 'Common_ExactMatch' : 'Common_PartialMatch'}>
								<IconButton
									name={exactMatch ? `${member.name}ExactMatch` : `${member.name}PartialMatch`}
									variant='text'
									color={exactMatch ? 'primary' : 'default'}
									size='small'
									aria-label='filter_word_match'
									onClick={handleToggleExactMatch}>
									{exactMatch ? <ExactMatchIcon /> : <PartialMatchIcon />}
								</IconButton>
							</BaseTooltip>
						</InputAdornment>
					)
				}
			}}
		/>
	);
};

export default FiltersControlTextSingleNew;
