import { useState, useEffect } from 'react';
import FilterTypes from '../constants/filterTypes';
import FilterType from '../enums/FilterType';
import FilterOperator from '../enums/FilterOperator';
import moment from 'moment';

export default (filters) => {
	const [filterDescriptor, setFilterDescriptor] = useState([]);

	const createFilter = (data) => buildDescriptor(data);

	useEffect(() => {
		if (!filters) return;
		setFilterDescriptor(buildDescriptor());
	}, []);

	return { filterDescriptor, createFilter, mapUrlFiltersToPageDescriptor };
};

const buildDescriptor = (data) => {
	const dataFilter = data ?? filters;

	let fd = [];

	for (let fItem of dataFilter) {
		if (!fItem) {
			fd.push(null);
			continue;
		}

		if (Array.isArray(fItem)) {
			fd.push(
				fItem.map((f) => {
					if (!f) return null;
					return {
						member: f.member.name,
						value: f.value,
						filterType: FilterTypes[f.member.type],
						filterOperator: f.operator,
						logicalOperator: f.logicalOperator,
						replace: f.replace ?? true
					};
				})
			);
		} else {
			fd.push({
				member: fItem.member.name,
				value: fItem.value,
				filterType: FilterTypes[fItem.member.type],
				filterOperator: fItem.operator,
				logicalOperator: fItem.logicalOperator,
				except: fItem.except,
				replace: fItem.replace ?? true
			});
		}
	}

	return fd;
};

const mapUrlFiltersToPageDescriptor = (urlFilters, filtersDefinitions) => {
	if (!urlFilters) return [];

	const mapped = [];
	for (let filter of urlFilters) {
		const { member, value, operator } = filter;
		const filterDefinition = filtersDefinitions.find(
			(cfm) => cfm.member.name === member && cfm.operator === Number(operator)
		);

		if (!filterDefinition) continue;
		if (
			filterDefinition.member.type === FilterType.Datetime.name &&
			Number(operator) === FilterOperator.Equal.value
		) {
			mapped.push(
				...[
					{
						...filterDefinition,
						value: moment(value).startOf('day').toISOString(),
						operator: FilterOperator.Ge.value
					},
					{
						...filterDefinition,
						value: moment(value).endOf('day').toISOString(),
						operator: FilterOperator.Le.value
					}
				]
			);
			continue;
		}

		mapped.push({
			...filterDefinition,
			value: value
		});
	}
	// const mapped = urlFilters.map((filter) => {
	// 	const { member, value, operator } = filter;
	// 	const filterDefinition = filtersDefinitions.find(
	// 		(cfm) => cfm.member.name === member && cfm.operator === Number(operator)
	// 	);

	// 	return {
	// 		...filterDefinition,
	// 		value: value
	// 	};
	// });

	return mapped;
};
