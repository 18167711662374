import { useState } from 'react';
import { Toolbar } from '@mui/material';
import { useLocale, useRequest } from '@sonar-web/common/src/hooks';
import EventBus from '@sonar-web/common/src/helpers/EventBus';
import BaseButton from '@sonar-web/common/src/components/Buttons/BaseButton';
import BaseDialog from '@sonar-web/common/src/components/BaseDialog/BaseDialog';
import ReduxHelpers from '@sonar-web/common/src/helpers/ReduxHelpers';
import TenantsService from '../TenantsService';
import Messages from '../Constants/Messages';
import Slices from '../Constants/Slices';

const slice = Slices.tenant;
const RequestPortAllocation = ({ type }) => {
	const { translate } = useLocale();
	const [showDialog, setShowDialog] = useState(false);

	const { pending, request } = useRequest({
		api: TenantsService.requestPortAllocation,
		slice,
		callback: () => {
			setShowDialog(false);
			EventBus.dispatch(Messages.PortAllocationRequested, { slice });
		}
	});

	const onSubmit = () => {
		const tenant = ReduxHelpers.getDirectlyFormStore('tenant.tenant');
		request({ type, tenantId: tenant.id });
	};

	return (
		<>
			<BaseButton name='requestPortAllocation' variant='outlined' onClick={() => setShowDialog(true)}>
				{translate('RequestPortAllocation')}
			</BaseButton>
			<BaseDialog
				title={`RequestPortAllocationTitle.${type}`}
				open={showDialog}
				setOpen={setShowDialog}
				onSubmit={onSubmit}
				pending={pending}
				progress
				disableBottomToolbar>
				{translate('RequestPortAllocationDescription')}
				<Toolbar disableGutters variant='dense' sx={{ justifyContent: 'flex-end', mt: '1rem', gap: '.5rem' }}>
					<BaseButton variant='outlined' name='baseDialogCancel' onClick={() => setShowDialog(false)}>
						No
					</BaseButton>
					<BaseButton name='baseDialogSubmit' pending={pending} onClick={onSubmit} progress>
						YesAllocate
					</BaseButton>
				</Toolbar>
			</BaseDialog>
		</>
	);
};

export default RequestPortAllocation;
